import React, { useEffect } from 'react';
import '../styles/globals.scss';
import Layout from '../components/Layout';
import Head from 'next/head';
import initAuth from '../firebase/initAuth'; // the module you created above
import { analytics } from '../firebase/firebaseApp';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useRouter } from 'next/router'; // Import to get the route

initAuth();

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const isEmbedRoute = router.pathname.startsWith('/embed');

  useEffect(() => {
    analytics;
  }, []);
  
  return (
    <>
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
        <meta name="description" content="Streamline your operaitons with a digital twin you can set up in minutes."/>
        <meta name="viewport" content="width=device-width,minimum-scale=1, initial-scale=1"/>
      </Head>
        <PayPalScriptProvider deferLoading={true}>
          {isEmbedRoute ? 
            <Component {...pageProps}/> :
            <Layout>
              <Component {...pageProps}/>
            </Layout> 
          }
        </PayPalScriptProvider>
    </>
  );
}

export default MyApp;
